@import "../../../../styleConst.scss";

.showSearchOffers {
  /* position: absolute;
  top: 0.5rem;
  left: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  svg {
    width: 3.25rem;
    height: 3.25rem;
    padding-bottom: 0.75rem;
  }
  .showSearchOffersButton {
    // padding: 0.6rem 0.5rem;
    height: 2.25rem;
    &.showSearchOffersActive {
      svg {
        fill: var(--success-color);
      }
    }
  }
}

.mapButtons {
  color: #666;
  width: 3.25rem;
  /* height: 40px; */
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;

  // .map-button {
  //   height: 40px;
  //   /* margin-top: 5px; */
  // }

  // .map-button-split {
  //   position: relative;
  //   overflow: hidden;
  //   width: 30px;
  //   height: 1px;
  //   margin: 0px 5px;
  //   background-color: rgb(230, 230, 230);
  //   top: 0px;
  // }
}

/* @media screen and (min-width: $mobile-view-breakpoint) {
  .show-offers {
    bottom: 11rem;
  }
} */
