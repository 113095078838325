$mobile-view-breakpoint: 768px;

$mobile-navbar-height: 60px;
$desktop-navbar-height: 90px;
$trip-tab-menu: 48px;

$mobile-menu-height: 60px;
$mobile-list-height: 148px;

$desktop-list-width: 50%;

$list-transition: 0.2s;
