@import "../../../../pages/styleConst.scss";

.gygTourInfoForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
  background: #1a2b49;
  width: 100%;
  border-radius: 1rem;
  min-width: unset;

  .warningMessage {
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gygTourInfoFormHeader {
    color: #fff;
    margin: 0 0 0.5rem 0.5rem;
  }

  .gygTourInfoFormList {
    display: block;
    align-items: center;
    // justify-content: space-between;
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.checkAvailabilityButton {
  background-color: #0071eb !important;
  border-radius: 1rem;
}
.selectHour {
  padding: 0.875rem;
  border-radius: 0.875rem;
  font-size: 0.9125rem;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.gygTourInfoListItemWrapper {
  display: block;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 1rem;
  background-color: white;
  border: 1px solid #dbdbdb;
}

.gygTourInfoFormListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  width: 22rem;
  border-bottom: 1px solid #dbdbdb;
}

.gygTourInfoPersonAge {
  color: gray;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.gygTourInfoPersonCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  color: #000;
  font-size: 0.9125rem;
  height: 3rem;
  padding: 0rem 2rem;

  &:hover {
    border-color: rgba(var(--text-primary-color-rgb), 0.8);
  }
}

.viatorTourInfoFormMaxWarning {
  color: blue;
  font-weight: 500;
  margin-top: 0.5rem;
}

.gygTourInfoPersonCountText {
  font-weight: bold;
  padding: 0 0.3125rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.gygTourInfoPeopleButton {
  background-color: #f5f5f5;
  padding: 0.8rem 1rem;
  font-weight: 400;
  border-radius: 0.75rem;
  min-width: 30%;
  margin-top: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.viatorHourSelect {
  select {
    text-align: center;
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .gygTourInfoForm {
    width: fit-content;
    min-width: 35rem;
    max-width: 50rem;
    padding: 1rem;

    .gygTourInfoFormList {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;

      .gygTourInfoPersonAge {
        position: absolute;
        bottom: -1.25rem;
        margin: 0;
        padding-left: 0.5rem;
      }
    }
  }

  .selectHour {
    margin-top: 0;
    text-align: start;
  }

  .gygTourInfoPeopleButton {
    margin-top: 0;
  }


  .viatorHourSelect {
    select {
      text-align: start !important;
    }
  }
}
