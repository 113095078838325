@import "../../../../styleConst.scss";

.showTours {
  .showToursButton {
    height: 40px;
    padding: 0.6rem 0.5rem;

    &.showToursActive {
      svg {
        fill: var(--success-color);
      }
    }
  }
}

.mapButtons {
  color: #666;
  width: 40px;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;

}


