@import "../../../../styleConst.scss";

.searchContainer {
  // .container-height
  // height: calc(100% - #{$mobile-navbar-height} - #{$trip-tab-menu});
  z-index: 2;
  width: 100%;
  background-color: var(--background-color);
  position: fixed;
  bottom: 0px;
  left: 0;
  transition: height #{$list-transition};

  &.searchContainerClose {
    height: 0;
  }
  .searchBoxClose {
    display: flex;
    float: right;

    .searchCloseIcon {
      width: 35px;
      height: 35px;
      cursor: pointer;
      float: right;
    }
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .searchContainer {
    // .container-height
    // height: calc(100% - #{$desktop-navbar-height} - #{$trip-tab-menu});
    width: 0;
    bottom: 0;
    transition: width #{$list-transition};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

    &.searchContainerOpen {
      width: $desktop-list-width;
      /* min-width: 408px; */
    }

    &.searchContainerClose {
      width: 0;
    }
  }
}
