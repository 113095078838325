.qrWriter {
  background-color: #0d31ab99;
  color: #fff;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .qrWriterHeader {
    font-size: 1.25rem;
    font-weight: bold;
  }
}
