@import "../../../../styleConst.scss";

.showCarRentOffers {
  /* position: absolute;
  top: 3.5rem;
  left: 5px; */

  .showCarRentOfferButton {
    height: 40px;
    padding: 0.6rem 0.5rem;

    svg {
      fill: #000;
    }

    &.showCarRentOfferActive {
      svg {
        fill: #d75645;
      }
    }
  }
}

.mapButtons {
  color: #666;
  width: 40px;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  margin-bottom: 0.5rem;

  // .map-button {
  //   height: 40px;
  // }

  // .map-button-split {
  //   position: relative;
  //   overflow: hidden;
  //   width: 30px;
  //   height: 1px;
  //   margin: 0px 5px;
  //   background-color: rgb(230, 230, 230);
  //   top: 0px;
  // }
}
