@import "../../pages/styleConst.scss";
@import url("//fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Overpass:wght@200;300;400;500;600;700&display=swap");

.landing {
  background: #fff;
  color: #222;
  font-family: "Overpass", sans-serif;
  font-weight: 400;
  width: 100%;
  line-height: normal;

  a {
    cursor: pointer;
  }

  .tm {
    font-weight: normal;
    padding-left: 0.25rem;
    opacity: 0.5;
  }
  /* Banner */
  .landingBanner {
    display: flex;
    height: 65%;

    a {
      margin-right: 0.5rem;
    }

    .landingBannerLogo {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-left: 2px solid rgba(var(--text-primary-color-rgb), 0.5);
      padding-left: 0.25rem;

      .landingBannerLogoText {
        color: var(--text-primary-color);
        font-size: 0.5rem;
        font-weight: 500;
        padding-left: 0.25rem;
      }

      .landingBannerLogoImg {
        width: 2.75rem;
        padding-left: 0.25rem;
      }
    }
  }

  /* Header */
  .landingHeader {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2030;
    padding: 16px 8px;
    background-color: var(--header-color);
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 10%);
    .landingHeaderNav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .landingHeaderTripianLogo {
        max-height: 4rem;
        max-width: 10rem;
        width: 100%;
        height: 100%;
      }

      .landingHeaderMenu {
        display: flex;
        align-items: center;
        margin: 0;
        margin-left: 0.75rem;
        padding: 0;
        list-style: none;
        li {
          a {
            text-decoration: none;
            font-size: 0.9rem;
            margin-left: 1.2rem;
            background-color: transparent;
            color: var(--header-text-color);
            font-family: "Heebo", sans-serif;
            font-weight: 700;
            &:hover {
              opacity: 0.8;
            }
            &.active {
              color: #f07a5e;
            }
          }
        }
      }
    }
  }
}

.iframe {
  width: 100%;
  height: 100%;
}

.landingActionsArea {
  z-index: 2;
  padding: 0 5rem;
  margin-top: 5rem;

  .landingCoverDesc {
    font-size: 1.5rem;
    max-width: 40rem;
  }
}

.landingSwiperContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mySwiper {
  width: 100% !important;
  height: 100% !important;
  padding: 2rem 0 !important;
}

.swiperSlide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: block !important;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .customerMessage {
    font-size: 1.5rem;
    margin-bottom: 1.8rem;
    display: block;
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    position: relative;
  }

  .whoCustomer {
    font-size: 1.5rem;
    position: relative;
    font-family: "Overpass", sans-serif;
    display: inline-block;
    margin-bottom: 1rem;

    &::before {
      content: "- ";
    }
  }
}

.swiperSlide span blockquote {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  width: 100% !important;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
}

.customerMessagesTitle {
  font-size: 1.8rem;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
}

.landingRowFluid {
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
}

.landingActionButtons {
  padding: 3rem 0px 25px;
  font-family: "Overpass", sans-serif;
  font-weight: 800;

  a {
    font-size: 0.9rem;
    margin-right: 0.7rem;
    background: linear-gradient(326deg, #d36951, #f57e62 79%);
    color: #fff;
    padding: 0.7rem 1.7rem;
    border-radius: 3rem;
    margin-right: 1rem;
    display: inline-block;
    text-decoration: none;
  }
}

.landingCoverTitle {
  font-size: 2rem;
  font-family: "Overpass", sans-serif;
  font-weight: 800;
  padding-bottom: 1.5rem;
  max-width: 40rem;
}

.landingMain {
  display: block;

  .landingCover {
    position: relative;
    background: #fff;
    height: calc(100vh);
    width: 100%;
    background-size: 88vh;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-top: 150px;
    background-image: none;
    &::after {
      content: "";
      background-size: cover;
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url("./img/elements/cover-bg-blue.png");
    }
  }

  .landingReadyToGet {
    background-color: #e6f0fc;
    text-align: center;

    .title {
      font-size: 2.2rem;
      font-family: "Overpass", sans-serif;
      font-weight: 800;
      padding-bottom: 2rem;
    }

    a {
      background: linear-gradient(326deg, #d36951, #f57e62 79%);
      color: #fff;
      font-size: 1.1rem;
      padding: 1.1rem 3rem;
      border-radius: 3rem;
      font-family: "Heebo", sans-serif;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .landingFooter {
    /* font-size: 1.3rem; */
    font-family: "Heebo", sans-serif;
    font-weight: 700;
    padding: 30px 0;

    a {
      color: #222;
      text-decoration: none;
      background-color: transparent;
    }

    .footerText {
      line-height: 1.71;
      color: #949494;
    }
  }
}

.margin50v {
  margin-top: 50px;
  margin-bottom: 50px;
}

.padding50v {
  padding-top: 50px;
  padding-bottom: 50px;
}

.landingHead {
  margin-bottom: 2rem;
  word-wrap: break-word;
  text-align: center;

  .landingTitle {
    font-size: 1.8rem;
    font-family: "Overpass", sans-serif;
    font-weight: 800;
  }
}

.landingSwipers {
  padding: 0;

  .swiperContainer {
    overflow: visible;
    z-index: 2;
  }
}

.howTripianWorks {
  .landingVaweCap {
    display: none;
  }

  .inner .grids {
    position: relative;
    max-width: unset;
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;
  }

  .dLgFlex {
    overflow: hidden;
    display: flex;
  }

  .howWorkGrid .inner {
    text-align: center;
    position: relative;
  }

  .landingImageCap {
    height: 150px;

    img {
      bottom: -30px;
      position: relative;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      border-style: none;
    }
    .landingOfContain {
      object-fit: contain;
      font-family: "object-fit: contain";
    }
  }

  .landingInfo {
    word-wrap: break-word;
    margin-top: 2rem;
    padding: 2rem 2.5rem;
    position: relative;

    .title {
      font-family: "Overpass", sans-serif;
      font-weight: 800;
      font-size: 1.5rem;
    }

    .desc {
      font-size: 1.1rem;
    }

    .descLast {
      font-size: 1.1rem;
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}

.landingTripianStatistics {
  position: relative;
  background-color: #e6f0fc;
  margin-bottom: 5.5rem;
  padding-bottom: 18px;
  padding-top: 3px;

  .landingGrids {
    display: block;
    flex-wrap: wrap !important;
    margin-bottom: 0 !important;

    .landingTripianStatisticsPadding {
      display: flex;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;

      .landingStatisticsDesc {
        font-size: 1.8rem;
        font-family: "Overpass", sans-serif;
        font-weight: 800;
        margin-left: 0;
        margin-bottom: -50px;
        line-height: 50px;
      }
    }

    .landingStatisticsCap {
      position: relative;
      height: 100%;
      padding-top: 10px;
      font-family: "Overpass", sans-serif;
      .landingStatistics {
        margin-top: 2.5rem;
        display: block;
        max-width: unset;
        text-align: center;

        .landingStatisticGrid {
          margin: 0;
          margin-bottom: 2.5rem;

          .landingLastItem {
            transform: translateY(15px);
          }

          .landingNumber {
            font-size: 2.5rem;
            font-family: "Overpass", sans-serif;
            font-weight: 800;
            color: #06549f;
            display: block;
          }

          .landingDesc {
            font-family: "Overpass", sans-serif;
            font-size: 1.7rem;
            font-weight: 400;
            display: block;
            min-width: 10rem;
          }
        }
      }
    }
  }
}

.landingFeelLikeListSwiper {
  padding: 0;
  margin: 0;
  max-width: unset;
  overflow: visible;

  .landingSwiperWrapper {
    display: block;
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-sizing: content-box;

    .landingSwiperSlide {
      border-bottom: 2px solid rgba(230, 240, 252, 0.3);
      padding: 1.5rem 0;
      margin-bottom: 1rem;
      display: block;

      .landingListItem {
        display: flex;
        cursor: pointer;

        &.landingListItemSelected {
          .landingIconCap {
            .landingIcon {
              background-color: #2e94f7;
              width: 60px;
              height: 60px;
            }
          }
          .landingBody {
            .landingBodyDesc {
              display: block;
            }
          }
        }

        .landingIconCap {
          margin-right: 1rem;
          .landingIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: #e6f0fc;
            border-radius: 50%;
            padding: 1rem;
          }
        }

        .landingBody {
          position: relative;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .landingBodyTitle {
            font-size: 1.2rem;
            margin-bottom: 0.2rem;
            color: #222;
            display: block;
            font-family: "Overpass", sans-serif;
            font-weight: 800;
          }

          .landingBodyDesc {
            display: none;
            font-size: 0.8rem;
            color: #666;
            margin-top: 0;
            margin-bottom: 1rem;
            font-family: "Overpass", sans-serif !important;
            font-weight: 400;
          }
        }
      }
    }

    .landingSwiperSlide:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .landingHeaderNav {
    padding: 0 1rem;
    .landingHeaderMenu {
      li {
        a {
          font-size: 1.3rem !important;
          margin-left: 1.6rem !important;
        }
      }
    }
  }
  .landingMain {
    display: block;

    .landingCover {
      position: relative;
      background: #fff;
      height: calc(100vh);
      width: 100%;
      background-size: 88vh;
      background-position: right bottom;
      background-repeat: no-repeat;
      padding-top: 150px;
      background-image: url("./img/elements/cover-bg-blue.png");
      &::after {
        content: "";
        background-size: cover;
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background-image: none !important;
      }
    }
  }

  .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
  }

  .landingCoverTitle {
    font-size: 3.8rem;
    line-height: 3.8rem;
    font-family: "Overpass", sans-serif;
    font-weight: 800;
    padding-bottom: 1.5rem;
  }

  .landingActionButtons {
    padding: 2rem 0 10px;
    font-family: "Heebo", sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    a {
      font-size: 0.9rem;
      margin-right: 0.7rem;
      background: linear-gradient(326deg, #d36951, #f57e62 79%);
      color: #fff;
      padding: 0.7rem 1.7rem;
      border-radius: 3rem;
      text-decoration: none;

      padding: 1.1rem 3rem;
      border-radius: 3rem;
    }
  }

  .landingHead {
    word-wrap: break-word;
    margin-bottom: 4rem;

    .landingTitle {
      font-size: 3.4rem;
      font-family: "Overpass", sans-serif;
      font-weight: 800;
    }
  }

  .landingSwipers {
    padding: 3rem;
  }

  .howTripianWorks {
    .landingVaweCap {
      display: block;
      max-width: 80vw;
      width: 80%;
      height: 270px;
      margin: 0 auto;
      text-align: center;
      position: absolute;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }

    .inner .grids {
      position: relative;
      max-width: 80vw;
      width: 80%;
      margin: 0 auto;
      margin-top: 4rem;
    }

    .howWorkGrid .inner {
      text-align: center;
      position: relative;
    }

    .landingImageCap {
      height: 300px;
      .landingOfContain {
        bottom: -15px;
        position: relative;
      }
    }

    .landingInfo {
      .desc {
        &::after {
          content: "";
          background-image: url("./img/icons/png/right-arrow.png");
          width: 75px;
          height: 75px;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: -75px;
          top: 30px;
        }
      }
    }
  }

  .customerMessagesTitle {
    font-size: 3.4rem;
  }

  .landingTripianStatistics {
    .landingGrids {
      display: flex;

      .landingTripianStatisticsPadding {
        .landingStatisticsDesc {
          font-size: 2.5rem;
          margin-left: 50px;
        }
      }

      .landingStatisticsCap {
        position: relative;
        height: 100%;
        padding-top: 10px;
        .landingStatistics {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          max-width: 80%;
          height: 100%;
          .landingStatisticGrid {
            margin-left: 100px;

            .landingNumber {
              font-size: 3rem;
              font-family: "Overpass", sans-serif;
              font-weight: 800;
              color: #06549f;
              display: block;
            }

            .landingDesc {
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }

  .landingFeelLikeListSwiper {
    .landingSwiperWrapper {
      .landingSwiperSlide {
        .landingListItem {
          &.landingListItemSelected {
            .landingIconCap {
              .landingIcon {
                width: 80px;
                height: 80px;
              }
            }
          }

          .landingIconCap {
            .landingIcon {
              width: 70px;
              height: 70px;
              padding: 1rem;
            }
          }

          .landingBody {
            .landingBodyTitle {
              font-size: 1.6rem;
            }

            .landingBodyDesc {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
