@import "../../../pages/styleConst.scss";

.appNavIconImage {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;

  .iconImage {
    width: 1.75rem;
    height: 1.75rem;
    svg {
      fill: var(--header-text-color) !important;
    }
  }

  .iconImageText {
    font-weight: bold;
    margin-left: 0.3rem;
  }

  &.tooltip {
    transition: all 250ms ease;
    padding-top: 0;
  }

  &.tooltip:hover {
    /* border-radius: 0.75rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid #383636; */
    /* text-shadow: 0 -1px #38363663; */
    // font-size: 1.05rem;

    .tooltiptext {
      width: 100px;
      margin-left: 0.25rem;
    }
  }
}

.appNavIconImageHideS {
  display: none !important;
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .appNavIconImageHideS {
    display: flex !important;
  }
}
