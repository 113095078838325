@import url("//fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "Herokid";
  src: url("../assets/fonts/Herokid-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Herokid";
  src: url("../assets/fonts/Herokid-SemiBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Brume";
  src: url("../assets/fonts/Brume-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
