.tcenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tbox {
  border-radius: 5px;
  border: 1px solid #00000066;
  margin: 1rem;
  padding: 1rem;
}

.stbox {
  border-radius: 5px;
  border: 2px solid #000000;
  margin: 1rem;
  padding: 1rem;
}
