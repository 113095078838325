.travelCompanionsFormWrapper {
  position: relative;
  background-color: var(--background-color);
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 3% !important;
  width: 100%;
  height: 100%;

  .travelCompanionsFormLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
