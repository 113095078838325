.cloneModal {
  z-index: 99999999;
  position: fixed;
  top: -100px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dialog {
    width: 400px;
    /* height: 300px; */
    background-color: var(--background-color);
    border-radius: 1rem;

    padding: 2rem 1rem;
  }

  .cancelButton {
    color: var(--background-color) !important;
  }
}
