@import "../../../pages/styleConst.scss";

.daySwitch {
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%) !important;
  top: 50%;

  .cityName {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    .editIcon {
      svg {
        fill: var(--header-text-color) !important;
      }
    }

    .cityNameSpan {
      font-size: 1rem;
    }
  }
}

// @media screen and (min-width: $mobile-view-breakpoint) {
//   .cityName {
//     margin-left: 1rem;
//   }
// }
