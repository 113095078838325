@import "../../../../styleConst.scss";

.mapSearch {
  padding: 0.5rem 1rem;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);

  .mapSearchHide {
    display: none;
  }
}

/* @media screen and (min-width: $mobile-view-breakpoint) {
  .map-search {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
 */
