@import "../pages/styleConst.scss";

.App {
  width: 100% !important;
  height: 100% !important;
  /* overflow-y: auto; */
}

.full-center {
  position: relative;
  margin: 0 auto;
  top: 50vh;
  transform: translateY(-50%);
  max-width: 450px;
  min-width: 300px;
  padding: 1rem;
}

.full-center-x {
  position: relative;
  margin: 2rem auto;
  max-width: 450px;
  min-width: 300px;
  padding: 1rem;
  z-index: 1;
}

.full-center-x-large {
  position: relative;
  margin: 2rem auto;
  max-width: 1088px;
  min-width: 300px;
  padding: 1rem;
}

.login-form-wrapper {
  position: relative;
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 10% 5%;
  width: 100%;
  height: 100%;
}

.background-full {
  background: var(--background-color);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.background {
  width: 100%;
  height: calc(100% - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}

.container-height {
  height: calc(100% - #{$mobile-navbar-height});
}

.container-height-tab {
  height: calc(100% - #{$mobile-navbar-height} - #{$trip-tab-menu});
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .container-height {
    height: calc(100% - #{$desktop-navbar-height});
  }
  .container-height-tab {
    height: calc(100% - #{$desktop-navbar-height} - #{$trip-tab-menu});
  }
}

/* Google Maps Zoom Control Position Fix */
/* .gmnoprint .gm-bundled-control .gm-bundled-control-on-bottom {
  bottom: 110px !important;
}
 */
