@import "../../../styleConst.scss";

.search-place-button {
  /* min-width: 9.35rem; */
  &:hover {
    border-color: rgba(var(--text-primary-color-rgb), 0.8) !important;
  }
}

.borderBottomLight {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.stepListMain {
  background-color: var(--background-color);
  display: flex;
  justify-content: space-evenly;
}

.searchToursButton {
  /* min-width: 9.35rem; */
  border: 0.0625rem solid rgba(var(--text-primary-color-rgb), 0.24);
  color: #000;
  background: #fff;
  padding: 0.5rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 3.1rem;

  &:hover {
    border-color: rgba(var(--text-primary-color-rgb), 0.75) !important;
    // transform: translateY(-3px);
  }

  .stepListSvg {
    fill: #000;
    width: 1.5rem;
    margin-right: 1.5rem;
  }
}

.stepList {
  text-align: left;
  // background-color: var(--background-color);
  /* color: green; */
  /* padding-bottom: calc(1.5 * #{$mobile-menu-height}); */
  max-height: 100% !important;
  background-color: var(--background-color);
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &::after {
    content: "";
    display: block;
    padding-bottom: calc(1.5 * #{$mobile-menu-height});
  }

  .switchCenter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .switch {
    display: inline-flex;
    align-items: center;
    position: relative;
    float: right;
  }
}

[data-custom-tooltip]:before {
  content: attr(data-custom-tooltip);
  display: none;
  position: absolute;
  background: #bbb;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.4;
  min-width: 7rem;
  text-align: center;
  border-radius: 4px;
  margin-left: 3.5rem;
}
[data-custom-tooltip-position='top']:before {
  left: 50%;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-80%);
}

[data-custom-tooltip-position='top']:before {
  bottom: 100%;
  margin-bottom: 6px;
}
[data-custom-tooltip]:after {
  content: '';
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
[data-custom-tooltip-position='top']:after {
  left: 50%;
  margin-left: -6px;
}
[data-custom-tooltip-position='top']:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #bbb;
}

[data-custom-tooltip]:hover:before,
[data-custom-tooltip]:hover:after {
  display: block;
  z-index: 50;
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .stepList::after {
    padding-bottom: 1.5rem;
  }
}
