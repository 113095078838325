.offerPayment {
  background-color: #0d31ab;
  color: #fff;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .button {
    background-color: #feb212;
    color: var(--text-primary-color);
  }
}
