@import "../styleConst.scss";

.formTemplateTripNextContainer {
  margin-bottom: 4rem;
}

.createUpdateTripLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media screen and (min-width: $mobile-view-breakpoint) {
  .formTemplateTripNextContainer {
    margin-bottom: 0;
  }
}
