@import "../../../../styleConst.scss";

.planRoute {
  position: absolute;
  top: 0.5rem;
  right: 5px;
  color: #666;
  width: 40px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .planRouteButton {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  .planRoute {
    top: 2.5rem;
  }
}
